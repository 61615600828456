<template>
    <div class="Sorting">
        <div v-for="(item, i) in question.question.values" :key="item.id" class="Sorting__Item"
            @click="toggleAssign(i)">
            <p class="Sorting__ItemLabel" v-html="item.value"></p>
            <div class="Sorting__ItemIndex">
                <span v-if="isAssigned(item.assignedIndex)">
                    {{ (item.assignedIndex + 1) < 10 ? '0' + (item.assignedIndex + 1) : (item.assignedIndex + 1) }}
                        </span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Sorting",
    props: {
        question: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            answers: [],
        };
    },
    methods: {
        isAssigned(val) {
            return typeof val === "number" && !isNaN(val);
        },

        toggleAssign(itemIndex) {
            const item = this.question.question.values[itemIndex];
            if (!this.isAssigned(item.assignedIndex)) {
                const freeIndex = this.findFirstFreeIndex();
                if (freeIndex !== -1) {
                    item.assignedIndex = freeIndex;
                }
            } else {
                this.shiftLeftFromIndex(item.assignedIndex);
            }
            this.addAnswers();
        },

        findFirstFreeIndex() {
            const n = this.question.question.values.length;
            const usedIndices = this.question.question.values
                .map((v) => (this.isAssigned(v.assignedIndex) ? v.assignedIndex : null));
            for (let i = 0; i < n; i++) {
                if (!usedIndices.includes(i)) {
                    return i;
                }
            }
            return -1;
        },

        shiftLeftFromIndex(fromIndex) {
            const currentItem = this.question.question.values.find(
                (v) => this.isAssigned(v.assignedIndex) && v.assignedIndex === fromIndex
            );
            if (currentItem) {
                currentItem.assignedIndex = null;
            }
            this.question.question.values.forEach((v) => {
                if (this.isAssigned(v.assignedIndex) && v.assignedIndex > fromIndex) {
                    v.assignedIndex = v.assignedIndex - 1;
                }
            });
        },

        addAnswers() {
            const assigned = this.question.question.values
                .filter((v) => this.isAssigned(v.assignedIndex))
                .sort((a, b) => a.assignedIndex - b.assignedIndex);

            const unassigned = this.question.question.values.filter(
                (v) => !this.isAssigned(v.assignedIndex)
            );

            const sortedArray = [];
            assigned.forEach((item, idx) => {
                sortedArray.push(`${idx + 1}-${item.id}`);
            });
            const offset = assigned.length;
            unassigned.forEach((item, idx) => {
                sortedArray.push(`${offset + idx + 1}-${item.id}`);
            });

            this.answers = sortedArray;
            const allAssigned = unassigned.length === 0;

            this.$emit("send-answers", {
                answer: { sortedVals: this.answers },
                question: this.question,
                answerValid: allAssigned,
            });
        },
    },
    mounted() {
        this.question.question.values = this.question.question.values.map((item) => {
            if (!this.isAssigned(item.assignedIndex)) {
                return { ...item, assignedIndex: null };
            }
            return item;
        });
        this.addAnswers();
    },
};
</script>

<style scoped>
.Sorting__Item {
    display: flex;
    justify-content: space-between;
    margin: 6px 0;
    padding: 10px;
    background-color: #fff;
    box-shadow: 0px 1px 6.72px 0.28px rgba(0, 0, 0, 0.1);
    cursor: pointer;
}

.Sorting__Item:hover {
    box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.05);
}

.Sorting__ItemLabel {
    margin: 0;
    color: #666666;
    font-size: 1rem;
}

.Sorting__ItemIndex {
    font-size: 16px;
    font-weight: 500;
    color: black;
    opacity: 0.6;
}

@media only screen and (max-width: 769px) {
    .Sorting__ItemLabel {
        font-size: 15px;
    }
}
</style>
