<template lang="html">
 <SortingNew v-if="question.question.newSortingView" :question="question" />
 <SortingOld v-else :question="question" />
</template>

<script>
import draggable from 'vuedraggable'
import SortingNew from './Sorting/SortingNew.vue'
import SortingOld from './Sorting/SortingOld.vue'

export default {
  name: 'Sorting',
  components: {
    draggable, 
    SortingNew,
    SortingOld,
  },  
  props: {
    question: {
      type: Object,
      default: null
    },
  },
  setup() {
    
  }
}
</script>
